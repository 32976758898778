import React from "react"

export const HeadlineProduction = ({headline, subheadline}) => (
    <div>

        {headline && <h1>{headline}</h1>}

        {subheadline && <p>{subheadline}</p>}

    </div>
)