import {gql} from "@apollo/client";
import {eventFragment} from "../fragments/EventFragment";

export const calendarQuery = gql`
    ${eventFragment}
    query( $siteId:[QueryArgument], $section:[String], $upcoming:[QueryArgument] ) {
        entries( section:$section, siteId:$siteId, date:$upcoming, orderBy:"date asc" ){
            ...EventFragment
        }
    }
`;
