import React from "react";
import styles from "./Layout.module.css";

export const BottomSpaced =
    ({children}) => {
        return (
            <div className={`${styles.bottomSpaced}`}>
                {children}
            </div>
        )
    }