import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';

import ProjectPage from "./ProjectsRouter";

import {About} from "./About/About";
import {Contact} from "./Contact/Contact";
import {Footer} from "../components/Footer/Footer";
import {Header} from "../components/Header/Header";
import {Home} from "./Home/Home";
import {News} from "./News/News";
import {ProductionDetail} from "./ProductionSingle/ProductionDetail";
import {Project} from "./ProjectSingle/Project"
import {ScrollManager} from "../components/ScrollManager";
import {Single} from "./Single/Single";



class AppRouter extends Component {
    render() {
        return (
            <div className="App">

                {/* Header */}
                <Route path={"/:lng"} children={route => <Header {...route}/> }/>

                <Switch>

                    {/* Projects */}
                    <Route path={["/:lng/projekte", "/:lng/projects"]} component={ProjectPage} />

                    {/* News */}
                    <Route path={["/:lng/aktuell", "/:lng/news"]} component={News} />

                    {/* Project Single */}
                    <Route path={["/:lng/projekt/:slug", "/:lng/project/:slug"]} component={Project} />

                    {/* Production Single */}
                    <Route path={["/:lng/produktion/:slug", "/:lng/production/:slug"]} component={ProductionDetail} />

                    {/* About */}
                    <Route path={["/:lng/ueber", "/:lng/about"]} component={About} />

                    {/* Contact */}
                    <Route path={["/:lng/kontakt", "/:lng/contact"]} component={Contact} />

                    {/* Impressum */}
                    <Route
                        path={["/:lng/impressum", "/:lng/imprint"]}
                        render={(route)=><Single {...route} sectionId={7} />}
                    />

                    {/* Single */}
                    <Route
                        path={["/:lng/data-protection", "/:lng/datenschutz"]}
                        render={(route)=><Single {...route} sectionId={8} />}
                    />

                    {/* Home */}
                    <Route path={"/:lng"} exact component={Home} />

                </Switch>

                {/* Footer */}
                <Route path="/:lng" children={Footer} />

                <Route component={ScrollManager} />

            </div>

        );
    }
}

export default AppRouter;
