import React, {useEffect} from 'react';
import { gql, useQuery } from '@apollo/client';
import {decidePageId} from "../../utils/decidePageId";
import {ArchiveContent} from "./ArchiveContent";
import {translations} from "../../utils/translations";
import {Loader} from "../../components/Loader/Loader";
import {WindowContext} from "../../utils/WindowContext";

const QUERY = gql`
    query( $siteId:[QueryArgument] ) {
        entries(
            section:["productions","projects"],
            siteId:$siteId,
            limit:100,
            orderBy:"postDate",
        ){
            title
            id
            uri
            typeHandle
            year: postDate @formatDateTime (format: "Y")
            sectionHandle
        }
    }
`;


const ArchivData = React.memo(props => {

    const {lng} = props.match.params;
    const {loading, error, data} = useQuery(
        QUERY,
        {
            variables: {
                siteId: decidePageId(lng),
                section: "events",
            }
        }
    );

    useEffect(() => {
        if (loading !== props.gettingData) {
            props.setGettingData(loading);
        }
    });

    if (loading) return <div className="ProjectIndex-Loader"><Loader /></div>;
    if (error) return <p>Error :(</p>;

    return (
        <div className={`container-fluid px-0`}>
            <h2 className="PageHeadline">{translations.archive[lng]}</h2>
            <ArchiveContent {...data} lng={lng}/>
        </div>
    );

});



export const Archiv = props => {
    return (
        <WindowContext.Consumer>
            {
                ({gettingData, setGettingData}) =>
                    <ArchivData
                        {...props}
                        setGettingData={setGettingData}
                        gettingData={gettingData}
                    />
            }
        </WindowContext.Consumer>
    );
};
