import React, {useState} from "react";
import {Link} from "react-router-dom";


export const ArchiveContent = props => {

    const [groupedEntries] = useState(groupBy(props.entries, "year"));

    return (
        <div className="ArchiveContent row">
            {renderGroups(groupedEntries,props.lng)}
        </div>
    );
};


const groupBy = (items, key) => items.reduce(
    (result, item) => ({
        ...result,
        [item[key]]: [
            ...(result[item[key]] || []),
            item,
        ],
    }),
    {},
);



const renderGroups = (obj,lng) => {
    let arr = [];
    for (const key of Object.keys(obj)) {
        arr.unshift(
            <div key={key} className="col-12 col-sm-6 col-md-4 mb-5">

                <div className="meta ">{key}</div>
                <ul className="list-unstyled">
                    {obj[key].map(entry => {
                        if( entry.typeHandle === "projectArchived" || entry.typeHandle === "productionArchived" ){
                            return <ArchiveEntry key={entry.id} {...entry} lng={lng}/>
                        }else{
                            return <ArchiveEntryLink key={entry.id} {...entry} lng={lng}/>
                        }
                    })}
                </ul>

            </div>
        );
    }
    return arr;
};


const ArchiveEntryLink = props => (
    <Link to={`/${props.lng}/${props.uri}`} className={`ProductionPreview`}>
        <li className="ArchiveEntry clearfix">
            <h3>{props.title}</h3>
        </li>
    </Link>
);


const ArchiveEntry = props => (
    <div className={`ProductionPreview`}>
        <li className="ArchiveEntry clearfix">
            <h3>{props.title}</h3>
        </li>
    </div>
);
