export const translations = {
    projects: { en:"Projects", de:"Projekte" },
    news: { en:"News", de:"News" },
    about: { en:"About", de:"Über" },
    contact: { en:"Contact", de:"Kontakt" },
    productions: { en:"Productions", de:"Produktionen" },
    moreProjects: { en:"Further Projects", de:"Weitere Projekte" },
    calendar: { en:"Calendar", de:"Kalender" },
    archive: { en:"Archive", de:"Archiv" },
    dancers: { en:"Dancers", de:"Tänzer*innen" },
    collaborators: { en:"Collaborators", de:"Collaborator*innen" },
    partners: { en:"Partners", de:"Partner*innen" },
    dataProtection: { de: "Datenschutz", en: "Data Protection" },
    imprint: { de: "Impressum", en: "Imprint" },
    supporters: { de: "Unterstützende", en: "Supporters" },
    contributors: { de: "Mitwirkende", en: "Contributors" }
};