import React from 'react';
import {NavLink} from "react-router-dom";
import {paths} from "../../utils/paths";
import {translations} from "../../utils/translations";
import {TextContainer} from "../../atoms/TextContainer/TextContainer";

const SubHeader = props => {
    const {lng} = props.match.params;
    return (
        <div className={`Subheader meta-small`}>
            <ul>

                <li className={`first`}>
                    <NavLink to={`/${lng}/${paths.projects.productions[lng]}`}>
                        <TextContainer>
                            {translations.productions[lng]}
                        </TextContainer>
                    </NavLink>
                </li>

                <li>
                    <NavLink to={`/${lng}/${paths.projects.moreProjects[lng]}`}>
                        <TextContainer>
                            {translations.moreProjects[lng]}
                        </TextContainer>
                    </NavLink>
                </li>

                <li>
                    <NavLink to={`/${lng}/${paths.projects.calendar[lng]}`}>
                        <TextContainer>
                            {translations.calendar[lng]}
                        </TextContainer>
                    </NavLink>
                </li>

                <li>
                    <NavLink to={`/${lng}/${paths.projects.archive[lng]}`}>
                        <TextContainer>
                            {translations.archive[lng]}
                        </TextContainer>
                    </NavLink>
                </li>

            </ul>
        </div>
    )
};

export default SubHeader;