import {format} from "date-fns"

import {de, en} from 'date-fns/locale'
import {withRouter} from "react-router-dom";
import {deriveLocaleFromLocation} from "../utils/deriveLocaleFromLocation";


export const DateFormatted = withRouter(
    ({date, nurMonat, location}) => {

        if (!date) return null;

        const formatStr = nurMonat ? "MMMM yyyy" : "dd. MMM. yyyy"
        const dateObj = new Date(date)

        const lng = deriveLocaleFromLocation(location);

        return format(
            dateObj,
            formatStr,
            {
                locale: lng === "de" ? de : en
            }
        )

    })


export const formatMonth = (date,locale) => {

    if (!date) return null;

    const formatStr = "MMMM yyyy"
    const dateObj = new Date(date)

    return format(
        dateObj,
        formatStr,
        {locale: locale === "de" ? de : en}
    )

}


export const formatDay = ({date, locale}) => {

    if (!date) return null;

    const formatStr = "dd. MMM. yyyy"
    const dateObj = new Date(date)

    return format(
        dateObj,
        formatStr,
        {locale: locale === "de" ? de : en}
    )

}