import React from 'react';
import {IconButton, IconContainer} from "../../../components/Icons/IconContainer";
import {Image} from "../../../components/Image/Image";


export const Collaborator = props => {
    return (
        <div className="Collaborator">

            <div
                dangerouslySetInnerHTML={{__html: props.bio}}
                className="redactor-simple Collaborator-block"
            />

            {
                props.link &&
                <div className="Collaborator-block CollaboratorView_Link">
                    <a href={props.link}>
                        <IconContainer size={1.5}>
                            <IconButton type={"arrow-north-east"}/>
                        </IconContainer>
                    </a>
                </div>
            }

            <div className="About-Portrait Collaborator-block">
                {props.photo.map(image => <Image {...image} key={image}/>)}
            </div>

        </div>
    );
};