import React,{useState} from 'react';

export const ScrollManager = props => {
    const [locKey, setLocKey] = useState(props.location.key);

    if(locKey !== props.location.key){

        window.scrollTo(0,0);
        setLocKey(props.location.key);

        const htmlTag = document.querySelector("html")
        htmlTag.classList.remove("modal-open");

    }

    return null;
};


export class ScrollTopOnEnter extends React.Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }
    render(){
        return null;
    }
}