import React from 'react';
import {GqlClient} from "./GqlClient";
import {ApolloProvider} from "@apollo/client";

export const CraftClient = props => {
    return (
        <ApolloProvider client={GqlClient}>
            {props.children}
        </ApolloProvider>
    );
};