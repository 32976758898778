import React from "react"
import styles from "./TextContainer.module.css";


export const TextContainer = ({children, inverted, disabled, font}) => {
    return (
        <div className={`
                ${font ? font : "meta-small"}
                ${styles.wrapper} 
                ${inverted && styles.inverted} 
                ${disabled && styles.disabled} 
            `}>
            {children}
        </div>
    )
}