import React, {useEffect} from 'react';
import {gql, useQuery} from '@apollo/client';
import {decidePageId} from "../../utils/decidePageId";
import {translations} from "../../utils/translations";
import {CalendarContent} from "../../views/CalendarContent";
import {Loader} from "../../components/Loader/Loader";
import {WindowContext} from "../../utils/WindowContext";

import {format} from 'date-fns'
import {calendarQuery} from "../../queries/CalendarQuery";


export const Calendar = props => {
    return (
        <WindowContext.Consumer>
            {
                ({gettingData, setGettingData}) =>
                    <CalendarData
                        {...props}
                        setGettingData={setGettingData}
                        gettingData={gettingData}
                    />
            }
        </WindowContext.Consumer>
    );
};


const CalendarData = React.memo(props => {

    const {lng} = props.match.params;

    const {loading, error, data} = useQuery(
        calendarQuery,
        {
            variables: {
                siteId: decidePageId(lng),
                section: "events",
                upcoming: `>= ${format(new Date(), "yyyy-LL-dd")}`,
            }
        }
    );

    useEffect(() => {
        if (loading !== props.gettingData) {
            props.setGettingData(loading);
        }
    }, [loading]);

    if (loading) return <div className="ProjectIndex-Loader"><Loader/></div>;
    if (error) return <p>Error :(</p>;

    return (
        <div className={`container-fluid px-0`}>

            <h2 className="PageHeadline">{translations.calendar[lng]}</h2>
            <CalendarContent entries={data.entries} lng={lng} />

        </div>
    );
});


