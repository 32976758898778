import React, {useEffect} from 'react';
import { gql, useQuery } from '@apollo/client';
import {decidePageId} from "../../utils/decidePageId";
import {ContactPage} from "./ContactPage";
import {Loader} from "../../components/Loader/Loader";
import {WindowContext} from "../../utils/WindowContext";

const QUERY = gql`
    query( $siteId:[QueryArgument] ) {
        entry( section:"contact", siteId:$siteId ){
            id
            title
            ... on contact_contact_Entry{
                id
                newsletterLink
                teamContact{
                    ...on teamContact_member_BlockType{
                        id
                        position
                        mail
                        telephone
                        memberName
                    }
                }
                socialMedia{
                    ...on socialMedia_socialMedia_BlockType{
                        id
                        mediaLink
                        mediaIcon{
                            url
                        }
                    }
                }
            }
        }
    }
`;



const ContactData = React.memo(props => {

    const {lng} = props.match.params;

    const {loading, error, data} = useQuery(
        QUERY,
        {
            variables: {
                siteId: decidePageId(lng),
            }
        }
    );

    useEffect(() => {
        if (loading !== props.gettingData) {
            props.setGettingData(loading);
        }
    });

    if (loading) return <div className="Page-Loader"><Loader/></div>;
    if (error) return <p>Error :(</p>;
    return <ContactPage {...data} {...props} />
});



export const Contact = props => {
    return (
        <WindowContext.Consumer>
            {
                ({gettingData, setGettingData}) =>
                    <ContactData
                        {...props}
                        setGettingData={setGettingData}
                        gettingData={gettingData}
                    />
            }
        </WindowContext.Consumer>
    );
};
