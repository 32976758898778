import gql from "graphql-tag";
import {eventFragment} from '../fragments/EventFragment';


export const productionDetailQuery = gql`
    ${eventFragment}
    query(
        $section:[String],
        $siteId:[QueryArgument],
        $id:[QueryArgument],
        $upcoming:[QueryArgument],
        $passed:[QueryArgument]
    ){
        entry( section:$section, siteId:$siteId, id:$id ){
            title
            slug
            ... on productions_productionCurrent_Entry{
                subheadline
                coverPhoto {
                    id
                    url
                    width
                    height
                }
                coverVideo
                description
                presskit{
                    title
                    url
                }
                credits{
                    ... on credits_column_BlockType{
                        list {
                            headline
                            content
                        }
                    }
                }
                presskit{
                    id
                    title
                    url
                }
                premiere{
                    title
                    ...EventFragment
                }
                photos{
                    id
                    title
                    url
                    width
                    height
                }
                alternativesTitelImage{
                    id
                    title
                    url
                    width
                    height
                }
            }
        }

        upcoming: entries(
            section:"events",
            siteId:$siteId,
            assignmentToProductionProject:$id,
            date:$upcoming,
            orderBy:"date asc"
        ){
            ...EventFragment
        }

        passed: entries(
            section:"events",
            siteId:$siteId,
            assignmentToProductionProject:$id,
            date:$passed,
            limit:20,
            orderBy:"date desc"
        ){
            ...EventFragment
        }

    }
`;
