import React from 'react';
import {Link, NavLink} from "react-router-dom";
import {paths} from "../../utils/paths";
import {translations} from "../../utils/translations";

export const Footer = props => {

    const pathname = props.location.pathname.split("/").slice(2).join("/");
    const lng = props.match ? props.match.params.lng : "de";

    return (
        <div className={"Footer"}>
            <div className="">

                <div className="Credits-Container">
                    © Tabea Martin
                    <Link to={`/${lng}/${paths.imprint[lng]}`} className="meta-small ml-3">
                        {translations.imprint[lng]}
                    </Link>
                    <Link to={`/${lng}/${paths.dataProtection[lng]}`} className="meta-small ml-3">
                        {translations.dataProtection[lng]}
                    </Link>
                </div>

                <div className="LngLink-Container">
                    <NavLink
                        to={`/en/${pathname}`}
                        activeClassName="active"
                        className="LngLink">
                        EN
                    </NavLink>
                    <NavLink
                        to={`/de/${pathname}`}
                        activeClassName="active"
                        className="LngLink">
                        DE
                    </NavLink>
                </div>

            </div>
        </div>
    )
}