import React, {Component, useState} from 'react';
import {CSSTransition} from "react-transition-group";

import {Image} from "../../components/Image/Image";
import {VimeoContainer} from "../../components/Vimeo/VimeoContainer";
import {differenceInMilliseconds} from "date-fns";
import {ScrollTopOnEnter} from "../../components/ScrollManager";
import {IconButton, IconContainer} from "../../components/Icons/IconContainer";
import Lightbox from "../../components/Lightbox/Lightbox";
import {Event} from "../../molecules/Event/Event";


class ProductionContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lightbox: false,
        }
        this.setLightbox = (_bool) => {
            this.setState({lightbox: _bool})
        }
    }

    render() {
        const {entry} = this.props;
        const {lng} = this.props.match.params;
        const {lightbox} = this.state;

        return (
            <div className="ProductionContent">

                <ScrollTopOnEnter/>
                <Lightbox
                    images={entry.photos}
                    active={lightbox}
                    setLightbox={this.setLightbox}
                />

                {/*{entry.coverVideo*/}
                {/*    ?*/}
                {/*    <VimeoContainer vimeoId={entry.coverVideo}/>*/}
                {/*    :*/}
                {/*    entry.coverPhoto.map(bild => <Image {...bild} key={bild.url}/>)*/}
                {/*}*/}

                <div className="Production-Intro">
                    <Cover
                        coverPhoto={entry.coverPhoto}
                        coverVideo={entry.coverVideo}
                        alternativesTitelImage={entry.alternativesTitelImage}
                    />
                </div>


                <div className="Production-Title container content-block">
                    <div className="row">
                        <div className="col-12 col-md-9">
                            <div className="ProductionTitile">
                                <h1>{entry.title}</h1>
                                <p>{entry.subheadline}</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="Production-Info container content-block">
                    <div className="row">
                        <div className="col-md-9">
                            <div
                                className="ProductionBeschreibung"
                                dangerouslySetInnerHTML={{__html: entry.description}}/>
                            <div className="Project-Credits">
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container content-block">
                    {
                        entry.presskit.length > 0 &&
                        <div className="Production-Presskit">
                            {entry.presskit.map(file =>
                                <a
                                    key={file.url}
                                    href={file.url}
                                    className="d-flex align-items-center"
                                >
                                    <IconContainer size={2}>
                                        <IconButton type="download"/>
                                    </IconContainer>
                                    <div className="ml-3">
                                        {file.title}
                                    </div>
                                </a>
                            )}
                        </div>
                    }
                </div>


                <div className="container content-block">


                    <div className="row">


                        <div className="col-12 col-md-3">

                            <h3>Shows</h3>

                            {
                                entry.premiere.length > 0 &&
                                <EventsContainer
                                    title="Premiere"
                                    initialLength={1}
                                    events={entry.premiere}
                                    lng={lng}/>
                            }
                            {
                                this.props.upcoming.length > 0 &&
                                <EventsContainer
                                    title="Upcoming"
                                    initialLength={2}
                                    events={this.props.upcoming}
                                    lng={lng}/>
                            }
                            {
                                this.props.passed.length > 0 &&
                                <EventsContainer
                                    title="Passed"
                                    initialLength={1}
                                    events={this.props.passed}
                                    lng={lng}/>
                            }

                        </div>

                        {
                            entry.credits.map((column, index) =>
                                <div key={index} className="Project-Credit-Item col-12 col-md-4 col-lg-3">
                                    {column.list.map(
                                        (item, index) => (
                                            <dl key={index}>
                                                <dt className="meta-small">{item.headline}</dt>
                                                <dd>{item.content}</dd>
                                            </dl>
                                        )
                                    )}
                                </div>
                            )
                        }

                    </div>
                </div>


                < div
                    className="container">

                    < div
                        className="Production-Photos row">
                        {
                            entry.photos.map(img =>
                                <div
                                    key={img.url}
                                    className="Production-Photos-Item col-12 col-md-9"
                                    onClick={() => this.setLightbox(true)}
                                >
                                    <Image {...img}/>
                                </div>
                            )
                        }
                    </div>
                </div>


            </div>
        );
    }
}

export default ProductionContent;


const EventsContainer = props => {
    const [length, setLength] = useState(props.initialLength);
    const increase = () => {
        setLength(length + 1);
    };
    const hide = () => {
        setLength(props.initialLength);
    };
    return (
        <div className="ProductionContent-EventsContainer">

            <h3 className="meta-large meta-headline">{props.title}</h3>

            {props.events.map(
                (event, index) =>
                    <EventContainer
                        key={event.id + "" + index}
                        {...event}
                        lng={props.lng}
                        in={index < length * 3}
                        type={props.type}
                    />
            )}

            <div className="ProductionContent-EventsControl ">
                {props.events.length > length * 3 &&
                <div className="mr-2" onClick={increase}>
                    <IconContainer size={2}>
                        <IconButton type="arrow-south"/>
                    </IconContainer>
                </div>
                }
                {length !== props.initialLength &&
                <div className="mr-2" onClick={hide}>
                    <IconContainer size={2}>
                        <IconButton type="arrow-north"/>
                    </IconContainer>
                </div>
                }
            </div>
        </div>
    )
};


const EventContainer = props => {

    const {date, venue, country, linkToVenue, nurMonat} = props;

    const passed = (
        differenceInMilliseconds(
            new Date(),
            new Date(2014, 6, 2, 12, 30, 20, 600)
        )

        > 0);

    return (
        <CSSTransition
            timeout={200}
            in={props.in}
            classNames="fade"
            unmountOnExit
            mountOnEnter
        >
            <div className={`ProductionContent-Event ${passed ? "passed" : "upcoming"}`}>
                <EventLink link={linkToVenue}>

                    <Event
                        linkToVenue={linkToVenue}
                        date={date}
                        country={country}
                        venue={venue}
                        nurMonat={nurMonat}

                    />

                </EventLink>
            </div>
        </CSSTransition>
    );
};

const EventLink = props => {
    if (props.link)
        return <a href={props.link} target="_blank" rel="noopener noreferrer">{props.children}</a>;
    else
        return props.children;
};


const Cover = ({coverVideo, coverPhoto, alternativesTitelImage}) => {

    if (coverVideo) {
        return <VimeoContainer vimeoId={coverVideo}/>
    } else if (alternativesTitelImage.length) {
        return alternativesTitelImage.slice(0, 1).map(bild => <Image {...bild} key={bild.url}/>)
    } else {
        return coverPhoto.slice(0, 1).map(bild => <Image {...bild} key={bild.url}/>)
    }


}