import React, {Component} from 'react';
import {Image} from "../Image/Image";
import {CSSTransition} from "react-transition-group";
import {IconButton, IconContainer} from "../Icons/IconContainer";


class Lightbox extends Component {

    componentDidUpdate(prevProps, prevState) {
        const htmlTag = document.querySelector("html")
        if(this.props.active){
            htmlTag.classList.add("modal-open");
        }else{
            htmlTag.classList.remove("modal-open");
        }
    }

    componentWillUnmount() {
        const htmlTag = document.querySelector("html")
        htmlTag.classList.remove("modal-open");
    }


    render() {
        const {images, active, setLightbox} = this.props;
        return (
            <CSSTransition
                in={active}
                timeout={300}
                classNames="fade"
                unmountOnExit
                mountOnEnter
            >
                <div className="Lightbox">
                    <div className="Lightbox-CloseBtn" onClick={() => setLightbox(false)}>
                        <IconContainer size={2}>
                            <IconButton type="close" dark/>
                        </IconContainer>
                    </div>
                    <div className="Lightbox-ImageContainer">
                        {images.map(
                            img =>
                                <div key={img.url}>
                                    <Image {...img} />
                                </div>
                        )}
                    </div>
                </div>
            </CSSTransition>
        );
    }
}

export default Lightbox;
