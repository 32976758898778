import React, {useEffect} from 'react';
import ProjectPreview from "./ProjectPreview";
import { gql, useQuery } from '@apollo/client';
import {decidePageId} from "../../utils/decidePageId";
import {translations} from "../../utils/translations";
import {Loader} from "../../components/Loader/Loader";
import {WindowContext} from "../../utils/WindowContext";


const PROJECTS_QUERY = gql`
    query( $siteId:[QueryArgument], $section:[String] ) {
        entries( section:$section, siteId:$siteId, orderBy: "postDate desc", type:"projectCurrent" ){
            id
            title
            uri
            typeHandle
            year: postDate @formatDateTime (format: "Y")
            ... on projects_projectCurrent_Entry{
                subheadline,
                coverPhoto {
                    id
                    url @transform( handle: "projectPreviewLandscape" )
                    width @transform( handle: "projectPreviewLandscape" )
                    height @transform( handle: "projectPreviewLandscape" )
                }
            }
        }
    }
`;


const ProjectsData = React.memo(props => {

    const {lng} = props.match.params;
    const {loading, error, data} = useQuery(
        PROJECTS_QUERY,
        {
            variables: {
                siteId: decidePageId(lng),
                section: "projects"
            }
        }
    );

    useEffect(() => {
        if (loading !== props.gettingData) {
            props.setGettingData(loading);
        }
    });

    if (loading) return <div className="ProjectIndex-Loader"><Loader /></div>;
    if (error) return <p>Error :(</p>;

    return (
        <div className={`container-fluid px-0`}>

            <h2 className={"PageHeadline"}>{translations.moreProjects[lng]}</h2>

            <div className="row">
                { data.entries.map( entry =>
                    <div key={entry.id} className={`col-12 col-sm-6 col-xl-4`}>
                        <ProjectPreview {...entry} lng={lng}/>
                    </div>
                )}
            </div>

        </div>
    );

});

export const Projects = props => {
    return (
        <WindowContext.Consumer>
            {
                ({gettingData, setGettingData}) =>
                    <ProjectsData
                        {...props}
                        setGettingData={setGettingData}
                        gettingData={gettingData}
                    />
            }
        </WindowContext.Consumer>
    );
};