import React from 'react';
import {translations} from "../../../utils/translations";

import {LinkWrapper} from "../../../components/LinkWrapper/LinkWrapper";
import {Collaborator} from "./Collaborator";
import {AboutTab} from "./AboutTab";


export const AboutCredits = ({entry, collaborators, lng}) => {

    return (
        <div className="About-Credits">

            <div className="row">
                <div className="col-12 col-md-9 col-xl-6 order-xl-2">

                    <AboutTab title={translations.collaborators[lng]} sup>
                        <AboutCollaborators items={collaborators}/>
                    </AboutTab>

                    <AboutTab title={translations.partners[lng]} sup>
                        <AboutPartners items={entry.partners}/>
                    </AboutTab>


                </div>
            </div>


        </div>
    );
};


const AboutCollaborators = props => {

    return props.items.map(item => {
            if (item.bio || item.link || item.photo.length) {
                return (
                    <AboutTab title={item.title} key={item.id}>
                        <Collaborator {...item}/>
                    </AboutTab>
                )
            } else return (
                <div key={item.id} className="AboutTab">
                    <div className="AboutTab-Header">
                        <div className="AboutTab-Title">
                            {item.title}
                        </div>
                    </div>
                </div>
            )

        }
    )

}


const AboutPartners = props => {

    return (
        <div className="">


            {props.items.map(
                item => (
                    <LinkWrapper to={item.link} key={item.link}>
                        <div className="AboutTab">
                            <div className="AboutTab-Header">
                                <div className="AboutTab-Title">
                                    {item.name}
                                </div>
                                <div className="AboutTab-Icon">
                                    {item.link && "↗"}
                                </div>
                            </div>
                        </div>
                    </LinkWrapper>
                )
            )}

        </div>
    );

}