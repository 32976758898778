import React from "react";
import {translations} from "../../utils/translations";
import PageContainer from "../../components/PageContainer/PageContainer";
import {Image} from "../../components/Image/Image";
import {AboutCredits} from "./credits/AboutCredits";
import {Accordeon} from "../../components/Accordeon/Accordeon";

export const AboutPage = props => {

    const {entry,collaborators, lng} = props;

    return (
        <PageContainer>


            <div className="container-fluid">

                <h2 className={"PageHeadline"}>
                    {translations.about[lng]}
                </h2>

                <div className="row">
                    <div className="col-12 col-md-9 col-xl-6 content-block">
                        <Accordeon>
                            <div dangerouslySetInnerHTML={{__html: entry.body}}/>
                        </Accordeon>
                    </div>
                </div>

                <div className="content-block">
                    <div className="About-Portrait">
                        {entry.photo.map(image => <Image {...image} key={image.url}/>)}
                    </div>
                </div>

            </div>


            <div className="About-MitwirkendeContainer container-fluid content-block">
                <h2 className="PageHeadline">{translations.contributors[lng]}</h2>
                <AboutCredits entry={entry} collaborators={collaborators} lng={lng} />
            </div>


            <div className="About-LogosContainer container-fluid">

                <h2 className="PageHeadline">
                    {translations.supporters[lng]}
                </h2>

                <div className="content-block">
                    <div className="About-Logos row ">
                        {
                            entry.supportersLogos.map(
                                block => {
                                    if (block.typeHandle === "uberschrift") {
                                        return (
                                            <div key={block.id} className="col-12 content-block">
                                                {block.text}
                                            </div>
                                        )
                                    } else if (block.typeHandle === "logo") {
                                        return (
                                            <div key={block.id}
                                                 className="About-Logo col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 content-block">
                                                <a href={block.linkUrl} target="_blank" rel="noopener noreferrer">
                                                    {block.file.map(logo => <Image {...logo} key={logo.url}/>)}
                                                </a>
                                            </div>
                                        )
                                    } else {
                                        return null;
                                    }
                                }
                            )
                        }
                    </div>
                </div>

            </div>


        </PageContainer>
    );
};