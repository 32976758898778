import React, {useState} from 'react';
import {Image} from "../../components/Image/Image";
import {CSSTransition} from "react-transition-group";
import {IconContainer, IconButton} from "../../components/Icons/IconContainer";
import {DateFormatted} from "../../atoms/DateFormatted";


export const NewsEntry = props => {

    const [open, setOpen] = useState(false);
    const {entry} = props;

    return (
        <div className={`NewsEntry ${open ? "open" : ""} `}>

            <div className="NewsEntry-Date inverted-spaced inverted meta-small">
                <DateFormatted date={entry?.postDate} nurMonat />
            </div>

            <div className="NewsEntry-Headline">
                <h3 className="">{entry.title}</h3>
            </div>


            <CSSTransition in={open} classNames={"fade"} timeout={300} mountOnEnter unmountOnExit>
                <React.Fragment>
                    {entry.news.map(block => {
                            return (
                                <div
                                    key={block.id}
                                    className={`NewsMatrix-Block block-${block.typeHandle}`}>
                                    <NewsBlock {...block}/>
                                </div>
                            )
                        }
                    )}
                </React.Fragment>
            </CSSTransition>


            <div className="NewsEntry-Button"  onClick={() => setOpen(!open)}>

                <IconContainer size={2}>
                    <IconButton dark type="plus" />
                </IconContainer>

            </div>


        </div>
    );
};


const NewsBlock = props => {
    switch (props.typeHandle) {
        case "text":
            return <div dangerouslySetInnerHTML={{__html: props.text}}/>;
        case "images":
            return props.files.map(img => <div key={img.url} className="NewsMatrix-Image"><Image {...img} wraped /></div> );
        case "video":
            return props.vimeoUrl;
        default:
            return null;
    }
};