import gql from "graphql-tag";

export const eventFragment = gql`
    fragment EventFragment on events_events_Entry{
        id
        title
        date
        month: date @formatDateTime (format: "M Y")
        country
        linkToVenue
        venue
        nurMonat
        assignmentToProductionProject {
            title
            uri
        }
    }
`

export const eventPreviewFragment = gql`
    fragment EventPreviewFragment on events_events_Entry{
        id
        date
        venue
        nurMonat
    }
`
