import React from 'react';
import PageContainer from "../../components/PageContainer/PageContainer";

export const ContactPage = props => {

    const {entry} = props;

    return (
        <PageContainer>
            <div className="container-fluid">
                {entry.teamContact.map(member =>
                    <div className="Contact-Member content-block" key={member.id}>
                        <div className="meta-small">{member.position}</div>
                        <h4 className="mb-0">{member.memberName}</h4>
                        <div>
                            <a href={`mailto:${member.mail}`}>{member.mail}</a>
                        </div>
                        {member.telephone} <br/>
                    </div>
                )}

                <div className="Contact-Media content-block">
                    {entry.socialMedia.map(media =>
                        <a href={media.mediaLink} key={media.id}>
                            {media.mediaIcon[0] &&
                            <img
                                key={media.mediaIcon[0].url}
                                src={media.mediaIcon[0].url}
                                alt=""
                                className="Contact-Media-Icon"
                            />
                            }
                        </a>
                    )}
                </div>

                <div className="Contact-Newsletter content-block">
                    <a href={entry.newsletterLink}>Newsletter</a>
                </div>

            </div>
        </PageContainer>
    );
};