import gql from "graphql-tag";

export const aboutQuery = gql`
    query( $siteId:[QueryArgument] ){
        entry( id:"21", siteId:$siteId ){
            title
            ... on about_about_Entry{
                body
                photo{
                    url @transform( handle: "tiny" )
                    title
                    width
                    height
                }
                partners{
                    name
                    link
                }
                unterstutzendeText
                supportersLogos{
                    ...on supportersLogos_uberschrift_BlockType{
                        id
                        typeHandle
                        text
                    }
                    ... on supportersLogos_logo_BlockType{
                        id
                        typeHandle
                        file{
                            url @transform( handle: "tiny" )
                            width
                            height
                            title
                        }
                        linkUrl
                    }
                }
            }
        }
        collaborators: entries( section:"collaborators"){
            ... on collaborators_collaborators_Entry{
                id
                title
                bio: collaboratorBio
                link: collaboratorLink
                photo: collaboratorPhoto{
                    title
                    url @transform( handle: "portrait" )
                    width
                    height
                }
            }
        }
    }
`
