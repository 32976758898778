import React from "react";

export const IconContainer = props => {
    const style = {
        width: props.size + `rem`,
        height: props.size + `rem`
    };
    return (
        <div style={style} className="icon-container">
            {props.children}
        </div>
    );
}


export const IconButton = props => {
    return (
        <button type={"button"} className={`icon-button ${props.dark ? "dark" : "light"}`}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                vectorEffect="none-scaling-stroke"
                strokeWidth="1.5px"
                rendering="crispEdges"
                stroke={props.dark ? "#111" : "#fefefe"}
            >
                <Toggler {...props} />
            </svg>
        </button>
    );
};

export const Icon = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            vectorEffect="none-scaling-stroke"
            strokeWidth="1px"
        >
            <Toggler {...props} />
        </svg>
    );
};


const Toggler = props => {
    switch (props.type) {
        case "plus":
            return <Plus/>;
        case "close":
            return <Close/>;
        case "arrow-south":
            return <ArrowSouth/>;
        case "arrow-north":
            return <ArrowNorth/>;
        case "arrow-north-east":
            return <ArrowNorthEast/>;
        case "download":
            return <Download/>;
        default:
            return null;
    }
}


const Plus = props => (
    <g>
        <line className="cls-1" x1="15" y1="7.5" x2="15" y2="22.5"/>
        <line className="cls-1" x1="22.5" y1="15" x2="7.5" y2="15"/>
    </g>
);

const Close = props => (
    <g>
        <line className="cls-1" x1="20.3" y1="9.7" x2="9.7" y2="20.3"/>
        <line className="cls-1" x1="20.3" y1="20.3" x2="9.7" y2="9.7"/>
    </g>
);

const ArrowSouth = props => (
    <polyline className="cls-1" points="22.5 10 15 18 7.5 10"/>
);

const ArrowNorth = props => (
    <polyline className="cls-1" points="22.5 19 15 11 7.5 19"/>
);

const Download = props => (
    <g>
        <line className="cls-1" x1="6.75" y1="22.53" x2="21.83" y2="22.53"/>
        <polyline className="cls-2" points="6.75 13.13 14.29 21.11 21.83 13.13"/>
        <line className="cls-1" x1="14.29" y1="6" x2="14.29" y2="21.11"/>
    </g>
);

const ArrowNorthEast = props => (
    <g>
        <polyline className="cls-1" points="12 10 20 10 20 18"/>
        <line className="cls-2" x1="20" y1="10" x2="10" y2="20"/>
    </g>
);


