import React, {useEffect, useState} from 'react';
import {Loader} from "../../components/Loader/Loader";
import {WindowContext} from "../../utils/WindowContext";
import ProductionPreviewCard from "../../organisms/ProductionPreviewCard/ProductionPreviewCard";
import {TranslatedMessage} from "../../atoms/MessageTranslated";

export const ProductionList = props => {
    return (
        <WindowContext.Consumer>
            {
                ({gettingData, setGettingData}) =>
                    <ProductionsData
                        {...props}
                        setGettingData={setGettingData}
                        gettingData={gettingData}
                    />
            }
        </WindowContext.Consumer>
    );
};


const ProductionsData = React.memo(({match, gettingData, setGettingData}) => {

    const {lng} = match.params;

    const spreadLoading=(bool)=>{
        setGettingData(bool);
        setLoading(bool);
    }

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [data, setData] = useState(null)


    useEffect(() => {

        spreadLoading(true);

        fetch(`/${lng}/api/productionList.json`)
            .then(res => res.json())
            .then(
                (result) => {
                    setData(result.data);
                    spreadLoading(false);
                },
                (error) => {
                    setError(error);
                    spreadLoading(false);
                }
            )

    }, [])

    if (loading) {
        return (<div className="ProjectIndex-Loader"><Loader/></div>)
    }

    if (error) {
        console.log(error)
        return (<p>Error :(</p>)
    }

    return (
        <React.Fragment>

            <h2 className={"content-block"}>
                <TranslatedMessage en="Productions on Tour" de="Produktionen auf Tour"/>
            </h2>

            <div className="row">
                {
                    data.map(
                        entry =>
                            <div key={entry.slug} className="col-12 col-md-6">
                                <ProductionPreviewCard
                                    {...entry}
                                    lng={lng}
                                />
                            </div>
                    )
                }
            </div>

        </React.Fragment>
    );
});

