import React, {useEffect} from 'react';
import {decidePageId} from "../../utils/decidePageId";
import {NewsEntry} from "./NewsEntry";
import PageContainer from "../../components/PageContainer/PageContainer";
import { gql, useQuery } from '@apollo/client';
import {translations} from "../../utils/translations";
import {Loader} from "../../components/Loader/Loader";
import {WindowContext} from "../../utils/WindowContext";
import {ScrollTopOnEnter} from "../../components/ScrollManager";

export const News = props => {
    return (
        <WindowContext.Consumer>
            {
                ({gettingData, setGettingData}) =>
                    <NewsData
                        {...props}
                        setGettingData={setGettingData}
                        gettingData={gettingData}
                    />
            }
        </WindowContext.Consumer>
    );
};


const QUERY = gql`
    query( $siteId:[QueryArgument] ){
        entries( section:"news", siteId:$siteId ){
            title
            id
            postDate
            ... on news_news_Entry{
                news{
                    ... on news_text_BlockType{
                        typeHandle
                        text
                        id
                    }
                    ... on news_images_BlockType{
                        typeHandle
                        id
                        files{
                            url
                            width @transform(handle:"medium")
                            height @transform(handle:"medium")
                            title @transform(handle:"medium") 
                        }
                    }
                    ... on news_video_BlockType{
                        id
                        typeHandle
                        vimeoUrl
                    }
                }
            }
        }
    }
`;

const NewsData = React.memo(props => {

    const {lng} = props.match.params;
    const {loading, error, data} = useQuery(
        QUERY,
        {
            variables: {
                siteId: decidePageId(lng)
            }
        }
    );

    useEffect(() => {
        if (loading !== props.gettingData) {
            props.setGettingData(loading);
        }
    });

    if (loading) return <div className="Page-Loader"><Loader/></div>;
    if (error) return <p>Error :(</p>;

    return (
        <PageContainer>
            <ScrollTopOnEnter />
            <div className="container-fluid">
                <h2 className={"PageHeadline"}>
                    {translations.news[lng]}
                </h2>
                <div className="row">
                    {data.entries.map((entry, index) =>
                        <div key={entry.id} className="col-12 col-md-6 col-xl-4">
                            <NewsEntry entry={entry} index={index}/>
                        </div>
                    )}
                </div>
            </div>
        </PageContainer>
    )
});


