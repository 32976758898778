import React, {useState,useEffect} from 'react';

export const VimeoContainer = props => {
    return (
        <div className="VimeoContainer">

            <VideoFrame {...props} />
            <Loader/>

        </div>
    )
};


class VideoFrame extends React.Component{

    shouldComponentUpdate(){
        return false;
    }

    render(){
        return(
            <iframe
                src={`https://player.vimeo.com/video/${this.props.vimeoId}?autoplay=1&color=fdfdfe&title=0&byline=0&portrait=0&background=1&muted=1`}
                title={this.props.vimeoId}
                width="100%"
                height="100%"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
            />
        );
    }

}

const Loader = props => {

    const [acitveIndex,setActiveIndex] = useState(-1);
    const [increment,setIncrement] = useState(0);


    useEffect(() => {

            const interval = setInterval(() => {
                if( acitveIndex + increment >= 3 ){
                    setIncrement(-1);
                }else if( acitveIndex + increment <= 0 ){
                    setIncrement(+1);
                }
                setActiveIndex( acitveIndex + increment );
            }, 200);

            return () => {
                clearInterval(interval);
            };

        }
    );


    return(
        <div className="VimeoLoader">
            <div className={`VimeoLoader-Element ${acitveIndex === 0 ? "active" : "" }`}/>
            <div className={`VimeoLoader-Element ${acitveIndex === 1 ? "active" : "" }`}/>
            <div className={`VimeoLoader-Element ${acitveIndex === 2 ? "active" : "" }`}/>
            <div className={`VimeoLoader-Element ${acitveIndex === 3 ? "active" : "" }`}/>
        </div>
    );
};