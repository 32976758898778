import React, {Component, useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import {paths} from "../../utils/paths";
import {translations} from "../../utils/translations";
import {WindowContext} from "../../utils/WindowContext";
import {ReactComponent as SVGClose} from "../../components/Icons/Icons_Close.svg";
import {TextContainer} from "../../atoms/TextContainer/TextContainer";
import {matchPath} from "react-router-dom"
import {MainNavigation} from "../../organisms/MainNavigation";

export const Header = props => (
    <WindowContext.Consumer>
        {dimensions =>
            <HeaderController {...props} dimensions={dimensions}/>
        }
    </WindowContext.Consumer>
);


export class HeaderController extends Component {

    constructor(props) {
        super(props);
        this.state = {
            desktopNav: true,
        };
        this.openNav = () => {
            this.setState({desktopNav: true});
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.dimensions !== prevProps.dimensions) {
            this.setState({desktopNav: false});
        }
    }

    render() {
        return <HeaderContainer {...this.props} {...this.state} openNav={this.openNav}/>;
    }

}


const HeaderContainer = props => {

    const [nav, setNav] = useState(false);

    useEffect(() => {
        setNav(false);
    }, [props.location]);

    const {lng} = props.match.params;
    const {scrollStart, scrollEnd, scrollDirection, gettingData} = props.dimensions;
    const showDesktopNav = (scrollStart || scrollEnd || scrollDirection === "up" || props.desktopNav);

    return (
        <div
            className={`Header ${showDesktopNav ? "open" : "closed"} ${nav ? "mobNavOpen" : "mobNavClosed"}`}
            onMouseMove={props.openNav}
        >

            <div className={`Header-Logo ${gettingData ? "loading" : ""}`}>
                <NavLink to={`/${lng}`}>
                    <Logo gettingData={gettingData}/>
                </NavLink>
            </div>

            <div className="Header-NavToggler" onClick={() => setNav(!nav)}>
                <div className="Header-NavToggler-Inner">
                    <SVGClose/>
                </div>
            </div>

            <MainNavigation lng={lng} />

        </div>
    );
};


class Logo extends Component {

    constructor(props) {
        super(props);

        this.letters = "Tabea\u00a0Martin".split("");

        this.state = {
            playing: this.props.gettingData,
            increment: -1,
            position: this.letters.length,
        }

        this.setPosition = pos => {
            this.setState({position: pos})
        }

        this.toggleIncrement = () => {
            this.setState({increment: this.state.increment * (-1)})
        }

        this.changePosition = () => {
            const {position, increment} = this.state;
            if (position + increment >= 0 && position + increment <= this.letters.length) {
                this.setPosition(position + increment);
            } else {
                this.setPosition(position - increment);
                this.toggleIncrement();
            }
        }

        this.startInterval = () => {
            this.animationInterval = setInterval(() => {
                this.changePosition();
            }, 100);
        }

        this.stopInterval = () => {
            clearInterval(this.animationInterval);
            this.animationInterval = false;
        }

        this.handleInterval = () => {
            if (this.props.gettingData && !this.animationInterval) {
                this.startInterval();
            } else if (!this.props.gettingData && this.animationInterval) {
                this.stopInterval()
            }
        }

    }


    componentDidMount(prevProps, prevState) {
        this.handleInterval();
    }

    componentDidUpdate(prevProps, prevState) {
        this.handleInterval();
    }


    render() {
        const {position} = this.state;
        return (
            <React.Fragment>
                {
                    this.letters.map((letter, index) => (
                        <span
                            key={index}
                            className={`LogoLetter ${this.props.gettingData && index >= position ? "loading" : ""}`}
                        >
                            { letter }
                        </span>
                    ))
                }
            </React.Fragment>
        )
    }

}