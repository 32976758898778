import React, {useEffect} from 'react';
import {useQuery} from '@apollo/client';
import {decidePageId} from "../../utils/decidePageId";
import {getEntryId} from "../../utils/getEventId";
import {format} from "date-fns";
import ProductionContent from "./ProductionContent";
import {Loader} from "../../components/Loader/Loader";
import {WindowContext} from "../../utils/WindowContext";
import {productionDetailQuery} from "../../queries/ProductionDetailQuery.ts";


export const ProductionData = React.memo(props => {

    const {lng} = props.match.params

    const {loading, error, data} = useQuery(
        productionDetailQuery,
        {
            variables: {
                siteId: decidePageId(lng),
                section: 'productions',
                id: getEntryId(props.match.params.slug),
                upcoming: `>= ${format(new Date(), "yyyy-LL-dd")}`,
                passed: `< ${format(new Date(), "yyyy-LL-dd")}`
            }
        }
    );

    useEffect(() => {
        if (loading !== props.gettingData) {
            props.setGettingData(loading);
        }
    });

    if (loading) {
        return <div className="Page-Loader"><Loader/></div>
    }

    if (error) {
        return <p>Error :(</p>
    }

    return <ProductionContent entry={data.entry} {...data} {...props} />;
});


export const ProductionDetail = props => {
    return (
        <WindowContext.Consumer>
            {
                ({gettingData, setGettingData}) =>
                    <ProductionData
                        {...props}
                        setGettingData={setGettingData}
                        gettingData={gettingData}
                    />
            }
        </WindowContext.Consumer>
    );
};

