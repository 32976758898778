import React, {Fragment} from "react";
import {Image} from "../../components/Image/Image";
import {CSSTransition} from "react-transition-group";
import {Link, NavLink} from "react-router-dom";
import {paths} from "../../utils/paths";
import {translations} from "../../utils/translations";
import {LinkWrapper} from "../../components/LinkWrapper/LinkWrapper";
import {animateScroll} from 'react-scroll';

export const HomeView = props => {

    const containerHeight = props.height / 3;
    const containerWidth = props.width / 2;

    const offsetTop = props.height * .1666 + containerHeight * .75;
    const index = Math.floor(
        (props.scrollY + offsetTop) / (containerHeight * 1.5)
    ) - 1;

    return (
        <HomeViewContent
            {...props}
            scrollY={null}
            index={index}
            containerHeight={containerHeight}
            containerWidth={containerWidth}
        />
    );
}


const HomeViewContent = React.memo(props => {
    const {lng} = props.match.params;
    return (
        <div className="HomeView" style={{paddingTop: props.height * .45}}>

            <TitleDisplay {...props} index={props.index}/>
            <HomeImageDisplay {...props} index={props.index}/>

            <div className="HomeBottom meta-small">

                <nav className="HomeNav">
                    <NavLink to={`/${lng}/${paths.projects.productions[lng]}`} className="HomeNav-Item first">
                        {translations.productions[lng]}
                    </NavLink>

                    <NavLink to={`/${lng}/${paths.projects.moreProjects[lng]}`} className="HomeNav-Item">
                        {translations.moreProjects[lng]}
                    </NavLink>

                    <NavLink to={`/${lng}/${paths.projects.calendar[lng]}`} className="HomeNav-Item">
                        {translations.calendar[lng]}
                    </NavLink>

                    <NavLink to={`/${lng}/${paths.projects.archive[lng]}`} className="HomeNav-Item">
                        {translations.archive[lng]}
                    </NavLink>
                </nav>

            </div>
        </div>
    )
});


const TitleDisplay = props => {

    const {entries, index} = props;
    const {lng} = props.match.params;

    return (
        <Fragment>

            {entries.map((entry, loopIndex) =>
                <CSSTransition
                    key={loopIndex}
                    in={loopIndex === index - 1}
                    classNames={"home-title-fade"}
                    timeout={300}
                    unmountOnExit
                    mountOnEnter
                >
                    <div className="HomeTitleDisplay">
                        <div className="HomeTitle">
                            <Link to={`${lng}/${entry.uri}`}>
                                {entry.title}
                            </Link>
                        </div>
                    </div>
                </CSSTransition>
            )}

        </Fragment>
    )
}


const HomeImageDisplay = (props) => {

    const {entries, index, containerHeight, containerWidth, homeEntry} = props;
    const slides = [homeEntry].concat(entries);
    const {lng} = props.match.params;

    return (
        <div className="HomeImageDisplay">

            {
                slides.map((entry, loopIndex) => {
                    let position;
                    if (index > loopIndex) {
                        position = "after";
                    } else if (index === loopIndex) {
                        position = "active"
                    } else {
                        position = "before";
                    }
                    return (
                        <div
                            className={`HomeImage HomeImage--${position} ${loopIndex === 0 ? "first" : ""} ${entry.typeHandle === "homepage" ? "HomeImage-NoLink" : ""}`}
                            style={{
                                height: containerHeight,
                                width: containerWidth,
                                marginBottom: containerHeight / 2,
                            }}
                            onClick={e => {
                                if (entry.slug === "homepage") {
                                    e.preventDefault();
                                    animateScroll.scrollMore(
                                        containerHeight * 2,
                                        {
                                            duration: 400,
                                            smooth: "easeInOutQuart",
                                        }
                                    );
                                }
                            }}
                            key={entry.id}
                        >
                            <div>
                                <LinkWrapper to={entry.uri ? `/${lng}/${entry.uri}` : null} intern>
                                    {entry.coverPhoto.map(img => <Image key={img.url} {...img} />)}
                                </LinkWrapper>
                            </div>
                        </div>
                    )

                })
            }
        </div>
    )
}
