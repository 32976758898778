import React, {useState} from 'react';
import {CSSTransition} from "react-transition-group";
import {Icon} from "../../../components/Icons/IconContainer";

export const AboutTab = props => {

    const {title, sup} = props;
    const [open, setOpen] = useState(false);

    return (
        <div
            className={`AboutTab`}
        >


            <div
                className={`AboutTab-Header isClickable ${sup ? "sup-lvl" : ""} ${open ? "open" : ""}`}
                onClick={() => setOpen(!open)}
            >
                <div className={`AboutTab-Title ${open ? "open" : ""} ${sup ? "meta-small" : ""}`}>
                    {title}
                </div>
                <div className="AboutTab-Icon">
                    <Icon type={open ? "arrow-north" : "arrow-south"}/>
                </div>
            </div>

            <CSSTransition
                in={open}
                classNames={"fade"}
                timeout={600}
                unmountOnExit
                mountOnEnter
            >
                <div className={`AboutTab-Body ${open ? "open" : ""} ${sup ? "sup-lvl" : ""}`}>
                    {props.children}
                </div>
            </CSSTransition>


        </div>
    );
};