import React from "react";
import styles from "./PreviewEvents.module.css";
import {EventPreview} from "../../molecules/Event/EventPreview";


export const PreviewEvents = ({events}) => {

    const placeholder = new Array(3 - events.length).fill();

    return (
        <div className={styles.container}>
            {
                events.concat(placeholder).map(
                    (event, index) => <EventPreview
                        key={event?.date + '' + index}
                        date={event?.date}
                        venue={event?.venue}
                        nurMonat={event?.nurMonat}
                        hideMobile={index>=2}
                    />
                )
            }

        </div>
    )

}