import React, {useState} from 'react';
import Measure from 'react-measure';
import {IconButton, IconContainer} from "../Icons/IconContainer";

export const Accordeon = props => {

    const [open, setOpen] = useState(false);
    //const [dimensions, setDimensions] = useState(false);


    return (
        <Measure bounds>
            {({measureRef, measure, contentRect}) => {

                const {height} = contentRect.bounds;

                return(
                    <div className={`Accordeon ${ height > 400 ? "cut" : "" }`}>

                        <div className={`AccordeonContainer ${open ? "open" : "closed"}`}>

                            <div className="AccordeonInner" ref={measureRef}>
                                {props.children}
                            </div>

                        </div>

                        <div
                            onClick={() => setOpen(!open)}
                            className="AccordeonButton d-flex justify-content-center"
                            >
                            <IconContainer size={2}>
                                <IconButton type={ open ? "arrow-north" : "arrow-south" } />
                            </IconContainer>
                        </div>



                    </div>
                )
            }}
        </Measure>
    );
}