import React from "react"
import {TextContainer} from "../../atoms/TextContainer/TextContainer";
import styles from "./EventPreview.module.css"
import {DateFormatted} from "../../atoms/DateFormatted";

export const EventPreview = ({date,venue,nurMonat,hideMobile}) => {
    return (
        <div className={`${styles.container} ${hideMobile?"d-none d-md-block":""}`}>

            <TextContainer inverted>
                <span><DateFormatted date={date} nurMonat={nurMonat}/></span>
            </TextContainer>

            <TextContainer>
                {venue}
            </TextContainer>

        </div>
    )
}
