import React from "react";
import {Switch, Route, Redirect} from "react-router-dom";
import AppRouter from "./AppRouter";


export const LngRouter = props => {
    return (
        <Switch>
            <Route path={["/en", "/de"]} component={AppRouter}/>
            <Route component={LanguageRedirect}/>
        </Switch>
    );
}


const LanguageRedirect = props => {
    const userLang = navigator.language || navigator.userLanguage;
    let _lng = userLang.slice(0, 2);
    if (_lng !== "de" && _lng !== "en") {
        _lng = "de";
    }
    return <Redirect to={`/${_lng}`}/>;
}