import React, {useEffect, useState} from "react";
import {Event} from "../molecules/Event/Event";
import {BottomSpaced} from "../atoms/Layout/BottomSpaced";
import {formatMonth} from "../atoms/DateFormatted";


export const CalendarContent = React.memo(({entries, lng}) => {


    const groupEntries = entries => {

        let eventGroups = [];

        entries.forEach(event => {

            const date = new Date(event.date)
            const month = formatMonth(date, lng)

            let group = eventGroups.find(group => group?.month === month);

            // group does not exits
            if (!group) {
                eventGroups.push({month: month, events: [event]})
            } else {
                group?.events?.push(event)
            }

        })


        return eventGroups
    }


    const [groupedEntries, setGroupedEntries] = useState(groupEntries(entries));

    useEffect(() => {
        setGroupedEntries(groupEntries(entries))
    }, [entries]);


    return (
        <div className="CalendarContent row">
            {groupedEntries?.map(group =>
                <div key={group?.month} className="col-12 col-sm-4 col-md-4 col-lg-3">

                    <div className="mb-4">{group.month}</div>


                    {
                        group.events.filter(e => e.nurMonat === true).map(event =>
                            <BottomSpaced key={event.id}>
                                <Event
                                    full
                                    venue={event?.venue}
                                    linkToVenue={event?.linkToVenue}
                                    assignmentToProductionProject={event?.assignmentToProductionProject}
                                    country={event?.country}
                                    date={event?.date}
                                    nurMonat={event?.nurMonat}
                                />
                            </BottomSpaced>
                        )
                    }


                    {
                        group.events.filter(e => e.nurMonat !== true).map(event =>
                            <BottomSpaced key={event.id}>
                                <Event
                                    full
                                    venue={event?.venue}
                                    linkToVenue={event?.linkToVenue}
                                    assignmentToProductionProject={event?.assignmentToProductionProject}
                                    country={event?.country}
                                    date={event?.date}
                                    nurMonat={event?.nurMonat}
                                />
                            </BottomSpaced>
                        )
                    }


                </div>
            )}

        </div>
    );
})