import React from "react"
import {Switch, Route} from "react-router-dom";

export const TranslatedMessage = ({en, de}) => {
    return (
        <Switch>
            <Route path={"/en"}>{en}</Route>
            <Route>{de}</Route>
        </Switch>
    )
}