import React from 'react';
import {BrowserRouter} from "react-router-dom";
import WindowProvider from "./utils/WindowProvider";
import {CraftClient} from "./utils/CraftClient";
import {LngRouter} from "./pages/LngRouter";

function App() {
    return (
        <BrowserRouter>
            <CraftClient>
                <WindowProvider>
                    <LngRouter/>
                </WindowProvider>
            </CraftClient>
        </BrowserRouter>
    );
}

export default App;