import React from "react"
import {TextContainer} from "../../atoms/TextContainer/TextContainer";
import styles from "./EventSmall.module.css"
import {DateFormatted} from "../../atoms/DateFormatted";
import {isPast} from "date-fns"
import {Link, matchPath, withRouter} from "react-router-dom";
import {IconButton, IconContainer} from "../../components/Icons/IconContainer";


export const Event = withRouter(({
                                     date,
                                     venue,
                                     linkToVenue,
                                     country,
                                     nurMonat,
                                     full,
                                     assignmentToProductionProject,
                                     location
                                 }) => {

    let dateIsPast = false;

    if (date) {
        dateIsPast = isPast(new Date(date))
    }

    let lng = matchPath(
        location.pathname,
        "/:lng"
    )?.params?.lng;


    return (
        <div className={`${styles.container}`}>

            <TextContainer inverted disabled={dateIsPast}>
                <div className="d-flex justify-content-between">
                    <span><DateFormatted date={date} nurMonat={nurMonat}/></span>
                    <div>{country}</div>
                </div>
            </TextContainer>

            <TextContainer font={"normal"}>
                <div className="d-flex justify-content-between">
                    <div>{venue}</div>
                    {(linkToVenue && !full) && <div>↗</div>}
                </div>

                {full && assignmentToProductionProject?.slice(0, 1).map(e =>
                    <h3 className={"mb-0"} key={e.uri}>{e.title}</h3>
                )}

                {full &&
                <div className="d-flex flex-wrap">
                    <Link to={`/${lng}/${assignmentToProductionProject[0].uri}`}
                          className="CalendarEntry-FooterDarkIcon">
                        <IconContainer size={2}>
                            <IconContainer size={2}>
                                <IconButton type={"plus"} dark/>
                            </IconContainer>
                        </IconContainer>
                    </Link>
                    {
                        (linkToVenue && full) &&
                        <a href={linkToVenue} target={"_blank"} className="">
                            <IconContainer size={2}>
                                <IconButton type={"arrow-north-east"}/>
                            </IconContainer>
                        </a>
                    }
                </div>
                }


            </TextContainer>


        </div>
    )
})