import React, {useState} from 'react';
import {Image} from "../../components/Image/Image";
import {ScrollTopOnEnter} from "../../components/ScrollManager";
import Lightbox from "../../components/Lightbox/Lightbox";

export const ProjectPage = props => {

    const [lightbox, setLightbox] = useState(false);

    return (
        <div className="Project container-fluid">

            <ScrollTopOnEnter/>
            <Lightbox images={props.photos} active={lightbox} setLightbox={setLightbox}/>

            <div className="row">

                <div className="col-12">
                    {props.coverPhoto[0] &&
                    <div className="ProjectCover content-block">
                        <Image {...props.coverPhoto[0]} />
                    </div>
                    }
                </div>


                <div className="col-12 col-md-8 col-lg-6">


                    <h2 className="content-block">{props.title}</h2>


                    <p className="content-block">{props.subheadline}</p>


                    <div dangerouslySetInnerHTML={{__html: props.description}} className="content-block"/>


                    <div className="ProjectCredits row">
                        {props.credits.map(col =>
                            <div key={col.id} className="col-12 col-md-6 col-xl-4 content-block">
                                {col.list.map(item =>
                                    <dl key={item.col1}>
                                        <dt className="meta-small">{item.col1}</dt>
                                        <dd>{item.col2}</dd>
                                    </dl>
                                )}
                            </div>
                        )}
                    </div>


                    <div className="ProjectPhotos">
                        {props.photos.map(img =>
                            <div
                                key={img}
                                className="ProjectPhoto content-block"
                                onClick={ () => setLightbox(true) }
                                >
                                <Image {...img} />
                            </div>
                        )}
                    </div>


                </div>
            </div>

        </div>
    );
};