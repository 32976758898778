import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {paths} from "../utils/paths";

import PageContainer from "../components/PageContainer/PageContainer";
import SubHeader from "../components/SubHeader/SubHeader";
import {Calendar} from "./Calendar/Calendar";
import {ProductionList} from "./ProductionList/ProductionList";
import {Archiv} from "./Archiv/Archiv";
import {Projects} from "./Projects/Projects";


const ProjectPage = props => {
    const {lng} = props.match.params;
    return (
        <PageContainer>
            <div className="container-fluid">

                <SubHeader {...props} />

                <Switch>
                    <Route
                        path={`/:lng/${paths.projects.productions[lng]}`}
                        exact
                        component={ProductionList}
                    />
                    <Route
                        path={`/:lng/${paths.projects.moreProjects[lng]}`}
                        exact
                        component={Projects}
                    />
                    <Route
                        path={`/:lng/${paths.projects.calendar[lng]}`}
                        exact
                        component={Calendar}
                    />
                    <Route
                        path={`/:lng/${paths.projects.archive[lng]}`}
                        exact
                        component={Archiv}
                    />
                    <Redirect to={`/${lng}/${paths.projects.productions[lng]}`}/>
                </Switch>

            </div>
        </PageContainer>
    )
}


export default ProjectPage;