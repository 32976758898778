export const paths = {
    projects: {
        de: "projekte", en: "projects",
        productions: { de: "projekte/produktionen", en: "projects/productions" },
        moreProjects: { de: "projekte/weitere-projekte", en: "projects/more-projects" },
        calendar: { de: "projekte/kalender", en: "projects/calendar" },
        archive: { de: "projekte/archiv", en: "projects/archive" }
    },
    production: { de:"produktion", en:"production" },
    news: { de: "aktuell", en: "news" },
    about: { de: "ueber", en: "about" },
    contact: { de: "kontakt", en: "contact" },
    imprint: { de: "impressum", en: "imprint" },
    dataProtection: { de: "datenschutz", en: "data-protection" }
};