import React, {Component} from 'react';
import "./ProjectPreview.scss";
import {Link} from "react-router-dom";
import {Image} from "../../components/Image/Image";
import {IconButton, IconContainer} from "../../components/Icons/IconContainer";


class ProjectPreview extends Component {
    render() {
        const {year, title, coverPhoto, uri, lng} = this.props;
        return (
            <Link to={`/${lng}/${uri}`} className={`ProjectPreview content-block`}>

                <div className={`area`}>

                    <div className="area-head meta-small inverted">
                        {year}
                    </div>

                    <div className="area-body">
                        {
                            coverPhoto.length > 0 &&
                            <div className="ProjectPreview-Bild">
                                { coverPhoto.map(img => <Image key={img} {...img} wraped />) }
                            </div>
                        }
                        <h3 className="ProjectPreview-Headline">{title}</h3>
                    </div>

                    <div className="ProductionPreview-Icon">
                        <IconContainer size={2}>
                            <IconButton type="plus" dark/>
                        </IconContainer>
                    </div>

                </div>
            </Link>
        );
    }
}

export default ProjectPreview;