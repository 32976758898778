import React from 'react';
import {Link} from "react-router-dom";


export const LinkWrapper = props => {
    const { to, intern } = props;

    if ( to ){
        if ( intern ) return ( <Link to={to} className="link-wrapper">{props.children}</Link> );
        else return ( <a href={to} target="_blank" rel="noopener noreferrer" className="link-wrapper">{props.children}</a> );
    }else{
        return <div className="link-wrapper">{props.children}</div>;
    }

};