import React, {useState} from "react";

export const Image = props => {
    const [loaded, setLoaded] = useState(false);

    if (props.wraped) return (
        <RatioWrapper width={props.width} height={props.height}>
            <img
                src={props.url}
                alt={props.title}
                width={props.width}
                height={props.height}
                className={`Image ${loaded ? "loaded" : "loading"}`}
                onLoad={() => setLoaded(true)}
            />
        </RatioWrapper>
    )
    else return (
        <img
            src={props.url}
            alt={props.title}
            width={props.width}
            height={props.height}
            className={`Image ${loaded ? "loaded" : "loading"}`}
            onLoad={() => setLoaded(true)}
        />
    )
};

export const RatioWrapper = props => (
    <div className="ratio-wrapper" style={{paddingTop: ((props.height / props.width * 100) + "%")}}>
        <div className="ratio-wrapper-inner">
            {props.children}
        </div>
    </div>
)
