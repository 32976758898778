import React, {useEffect} from 'react';
import {decidePageId} from "../../utils/decidePageId";
import {useQuery} from '@apollo/client';
import {AboutPage} from "./AboutPage";
import {Loader} from "../../components/Loader/Loader";
import {WindowContext} from "../../utils/WindowContext";
import {aboutQuery} from "../../queries/AboutQuery";


const AboutData = React.memo(props => {

    const {lng} = props.match.params;

    const {loading, error, data} = useQuery(
        aboutQuery,
        {
            variables: {
                siteId: decidePageId(lng)
            }
        }
    )

    useEffect(() => {
        if (loading !== props.gettingData) {
            props.setGettingData(loading);
        }
    })

    if (loading) {
        return <div className="Page-Loader"><Loader/></div>
    }

    if (error) {
        console.log(error)
        return <p>Error :(</p>
    }

    return (
        <AboutPage
            entry={data.entry}
            collaborators={data.collaborators}
            lng={lng}
        />
    );

})


export const About = props => {
    return (
        <WindowContext.Consumer>
            {
                ({gettingData, setGettingData}) =>
                    <AboutData
                        {...props}
                        setGettingData={setGettingData}
                        gettingData={gettingData}
                    />
            }
        </WindowContext.Consumer>
    );
};
