import React, {useEffect} from 'react';
import PageContainer from "../../components/PageContainer/PageContainer";
import { gql, useQuery } from '@apollo/client';

import {decidePageId} from "../../utils/decidePageId";
import {getEntryId} from "../../utils/getEventId";
import {Loader} from "../../components/Loader/Loader";
import {ProjectPage} from "./ProjectPage";
import {WindowContext} from "../../utils/WindowContext";


const QUERY = gql`
    query( $section:[String], $siteId:[QueryArgument], $id:[QueryArgument] ) {
        entry( section:$section, siteId:$siteId, id:$id ){
            title
            slug
            ...on projects_projectCurrent_Entry{
                coverPhoto {
                    id
                    url
                    width
                    height
                }
                description
                subheadline
                credits{
                    ... on credits_column_BlockType{
                        id
                        list{
                            col1
                            col2
                        }
                    }
                }
                photos{
                    id
                    title
                    url
                    width
                    height
                }
            }
        }
    }
`;


export const ProjectData = React.memo(props => {

    const {lng} = props.match.params
    const {loading, error, data} = useQuery(
        QUERY,
        {
            variables: {
                siteId: decidePageId(lng),
                section: 'projects',
                id: getEntryId(props.match.params.slug),
            }
        });

    useEffect(() => {
        if (loading !== props.gettingData) {
            props.setGettingData(loading);
        }
    });

    if (loading) return <div className="Page-Loader"><Loader/></div>;
    if (error) return <p>Error :(</p>;

    return (
        <PageContainer>
            <ProjectPage {...data.entry} />
        </PageContainer>
    );

});



export const Project = props => {
    return (
        <WindowContext.Consumer>
            {
                ({gettingData, setGettingData}) =>
                    <ProjectData
                        {...props}
                        setGettingData={setGettingData}
                        gettingData={gettingData}
                    />
            }
        </WindowContext.Consumer>
    );
};
