import React from "react"
import {matchPath, NavLink} from "react-router-dom";
import {paths} from "../utils/paths";
import {TextContainer} from "../atoms/TextContainer/TextContainer";
import {translations} from "../utils/translations";

export const MainNavigation = ({ lng,  })=>{
    return(
        <nav className={`Header-Navigation`}>

            <NavLink
                className="Header-Navigation-Item first"
                to={`/${lng}/${paths.projects[lng]}`}
                isActive={(match, location) => {
                    return matchPath(
                        location.pathname,
                        {
                            path: [
                                "/:lng/produktion",
                                "/:lng/production",
                                "/:lng/project",
                                "/:lng/projekt",
                                "/:lng/projects",
                                "/:lng/projekte",
                            ],
                            exact: false,
                        }
                    )
                }}
            >
                <div className="Header-Navigation-Item-Inner meta-small">
                    <TextContainer>
                        {translations.projects[lng]}
                    </TextContainer>
                </div>
            </NavLink>

            <NavLink to={`/${lng}/${paths.news[lng]}`} className="Header-Navigation-Item">
                <div className="Header-Navigation-Item-Inner meta-small">
                    <TextContainer>
                        {translations.news[lng]}
                    </TextContainer>
                </div>
            </NavLink>

            <NavLink to={`/${lng}/${paths.about[lng]}`} className="Header-Navigation-Item">
                <div className="Header-Navigation-Item-Inner meta-small">
                    <TextContainer>
                        {translations.about[lng]}
                    </TextContainer>
                </div>
            </NavLink>

            <NavLink to={`/${lng}/${paths.contact[lng]}`} className="Header-Navigation-Item">
                <div className="Header-Navigation-Item-Inner meta-small">
                    <TextContainer>
                        {translations.contact[lng]}
                    </TextContainer>
                </div>
            </NavLink>

        </nav>
    )
}