import React from "react"
import {Link} from "react-router-dom";
import {Image} from "../../components/Image/Image";
import {IconButton, IconContainer} from "../../components/Icons/IconContainer";
import styles from "./ProductionPreviewCard.module.css"
import {HeadlineProduction} from "../../atoms/HeadlineProduction/HeadlineProduction";
import {PreviewEvents} from "../PreviewEvents/PreviewEvents";

const ProductionPreview = React.memo(props => {

    return (
        <Link
            to={`/${props.lng}/${props.uri}`}
            className={styles.wrapper}
        >
            <div className={styles.inner}>

                <PreviewEvents events={props.events} />

                <div>
                    <div className={styles.figure}>
                        {props.coverPhoto.length > 0 && props.coverPhoto.map(bild =>
                            <Image key={bild.url} {...bild} wraped/>
                        )}
                    </div>
                    <div className={styles.text}>
                        <HeadlineProduction
                            headline={props.title}
                            subheadline={props.subheadline}
                        />
                    </div>
                </div>


                <div className={styles.footer}>
                    <IconContainer size={2}>
                        <IconButton dark type="plus"/>
                    </IconContainer>
                </div>


            </div>
        </Link>
    );
});

export default ProductionPreview;