import React from 'react';
import PageContainer from "../../components/PageContainer/PageContainer";
import { gql, useQuery } from '@apollo/client';

import {decidePageId} from "../../utils/decidePageId";
import {Loader} from "../../components/Loader/Loader";


const QUERY = gql`
    query( $sectionId:[QueryArgument], $siteId:[QueryArgument], $id:[QueryArgument] ) {
        entry( sectionId:$sectionId, siteId:$siteId, id:$id ){
            title
            ... on impressum_impressum_Entry{
                bodytext
            }
            ... on datenschutz_datenschutz_Entry{
                bodytext
            }
        }
    }
`;


export const Single = React.memo(props => {

    const {lng} = props.match.params;
    const {loading, error, data} = useQuery(
        QUERY,
        {
            variables: {
                siteId: decidePageId(lng),
                sectionId: props.sectionId,
            }
        });

    if (loading) return <div className="Page-Loader"><Loader/></div>;
    if (error) return <p>Error :(</p>;

    return (
        <PageContainer>

            <div className="container-fluid">
                <div className="row">

                    <div className="col-12 col-md-6">
                        <h1 className="size-1 content-block">{data.entry.title}</h1>
                        <div dangerouslySetInnerHTML={{__html:data.entry.bodytext}} className="" />
                    </div>

                </div>
            </div>

        </PageContainer>
    )
});